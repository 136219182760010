import { Button, Dropdown } from "antd";
import {
  AppleFilled,
  GoogleOutlined,
  WindowsFilled,
  YahooFilled,
} from "@ant-design/icons";
import fileSaver from "file-saver";
import AddToCalHelpersClass, {
  CalendarType,
} from "react-add-to-calendar/lib/helpers";

import { ApiState, useEvent } from "../../api";
import hydrateDate from "../../hydrateDate";
import { useCallback } from "react";

const addToCalHelpers = new AddToCalHelpersClass();

type IEventApi = Awaited<ReturnType<typeof useEvent>>;

export interface AddToCalendarProps {
  event: Exclude<IEventApi, ApiState>;
}
export const AddToCalendar: React.FC<AddToCalendarProps> = (props) => {
  const { event } = props;

  const startDate = hydrateDate(event.startDate);
  const endDate = hydrateDate(event.endDate);

  const hClick = useCallback(
    ({ key }: { key: string }) => {
      const url = addToCalHelpers.buildUrl(
        {
          title: event.name,
          description: event.description,
          startTime: startDate.toDate(),
          endTime: (endDate || startDate.add(1, "hour")).toDate(),
          location: event.location?.name ?? "Unknown",
        },
        key as CalendarType,
        false,
      );
      if (url.startsWith("http")) {
        window.open(url, "_blank");
      } else if (url.startsWith("data")) {
        fileSaver(url);
      } else {
        const isoDate = startDate.format("YYYY-MM-DD");
        const filesafeName = event.name.replaceAll(/[^a-zA-Z0-9]/g, "_");
        const filename = `${isoDate}_${filesafeName}.ics`;
        const blob = new Blob([url], { type: "text/calendar;charset=utf-8" });
        fileSaver(blob, filename);
      }
    },
    [event, startDate, endDate],
  );
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "google",
            label: "Google",
            icon: <GoogleOutlined />,
            onClick: hClick,
          },
          {
            key: "yahoo",
            label: "Yahoo",
            icon: <YahooFilled />,
            onClick: hClick,
          },
          {
            key: "outlookcom",
            label: "Outlook.com",
            icon: <WindowsFilled />,
            onClick: hClick,
          },
          {
            key: "ics",
            label: "Apple",
            icon: <AppleFilled />,
            onClick: hClick,
          },
        ],
      }}
    >
      <Button>Add to calendar</Button>
    </Dropdown>
  );
};
export default AddToCalendar;
