import { Spin, SpinProps, Tooltip } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import _  from 'lodash';
import React, { ComponentProps } from 'react';

import usePromise, { PromiseState } from '../hooks/usePromise';

export interface LazyComponentProps<T extends React.ComponentType> {
    spin?: SpinProps;
    promise: Promise<T>;
};
export const LazyComponent = <T extends React.ComponentType>(props: LazyComponentProps<T> & ComponentProps<T>) => {
    const [s, v] = usePromise(props.promise);
    if (s === PromiseState.notStarted || s === PromiseState.pending) {
        return <Spin {...props.spin ?? {}} />;
    } else if (s === PromiseState.rejected) {
        return <Tooltip title="Failed to load UI element"><WarningFilled /></Tooltip>
    }
    return React.createElement(v, _.omit(props, ['spin', 'promise']));
};
export default LazyComponent;