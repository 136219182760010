import { Alert, message } from 'antd';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';

import { useToken, useTokenSetter } from '../../auth';

export const LoginTokenPage: React.FC = () => {
    const nav = useNavigate();
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const code = params.get('code');
    const {data: tokenData, error} = useToken({
        code, redirectUri: useHref('/'),
    })

    useEffect(() => {
        if (error) {
            console.error(error);
            message.error('Error attempting to log you in');
        }
    }, [error]);

    const setToken = useTokenSetter();
    useEffect(() => {
        if (tokenData) {
            setToken(tokenData);
            nav('/');
        }
    }, [nav, setToken, tokenData]);

    return error 
        ? <Alert message="Login error" description="Error attempting to log you in" type="error" showIcon />
        : <div>Logging you in...</div>;
};
export default LoginTokenPage;