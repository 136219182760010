import { Breadcrumb, ConfigProvider, Layout, theme, Typography } from 'antd';
import { ConfigProvider as MobileConfigProvider } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US'
import React, { useCallback, useMemo } from 'react';
import { createBrowserRouter, Outlet, RouterProvider, useMatches, useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';

import { useIsAdmin } from './api';
import LoginTokenPage from './components/authPages/LoginTokenPage';
import LoginStartPage from './components/authPages/LoginStartPage';
import EventCalendarPage from './components/eventPages/EventCalendarPage';
import EventPage from './components/eventPages/EventPage';
import LazyComponent from './components/LazyComponent';
import { AuthProvider } from './auth';

const { Header, Content } = Layout;

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    min-height: 100vh;
  }
  body, #root {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const AppHeader = styled(Header)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppTitle = styled(Typography.Title)`
  margin: 0;
  flex-grow: 1;
  text-align: center;
`;
const AppHeaderSides = styled.div<{
  justifyContent: 'flex-start' | 'flex-end';
}>`
  dysplay: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  flex-shrink: 1;
`;
const AppContent = styled(Content)`
  padding: 0 ${props => props.theme.paddingContentHorizontal * 3}px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const AppContainer = styled.div`
  background: ${props => props.theme.colorBgContainer};
  color: ${props => props.theme.colorText};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const AppBreadcrumbStyle = styled(Breadcrumb)`
  margin: ${props => props.theme.paddingContentVertical}px 0;
`;
const AppBreadcrumb: React.FunctionComponent = () => {
  let matches = useMatches();
  let crumbs = matches
    .filter(m => Boolean((m.handle as any)?.crumb))
    .map(m => (m.handle as any).crumb(m.data));
  return <AppBreadcrumbStyle>{crumbs}</AppBreadcrumbStyle>
};


const AppWithConfig: React.FunctionComponent = () => {
  const { token } = theme.useToken();
  const isAdmin = useIsAdmin();
  const adminMenuProm = useMemo(() => isAdmin.isAdmin ? import('./admin/AdminMenu').then(m => m.default) : null, [isAdmin]);

  const nav = useNavigate();
  const hHome = useCallback(() => nav('/'), [nav]);

  return (
    <ThemeProvider theme={token}>
      <GlobalStyle />
      <Layout>

        <AppHeader>
          <AppHeaderSides justifyContent="flex-start"></AppHeaderSides>
          <AppTitle><a href="/" onClick={hHome}>FUCKing Events</a></AppTitle>
          <AppHeaderSides justifyContent="flex-end">
            { isAdmin.isAdmin && <LazyComponent promise={adminMenuProm as NonNullable<typeof adminMenuProm>} /> }
          </AppHeaderSides>
        </AppHeader>

        <AppContent>
          <AppBreadcrumb />
          <AppContainer>
            <Outlet />
          </AppContainer>
        </AppContent>

      </Layout>
    </ThemeProvider>
  )
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppWithConfig />,
    children: [
      {
        path: 'admin',
        element: <Outlet />,
        handle: {
          crumb: () => <Breadcrumb.Item key="admin">Admin</Breadcrumb.Item>,
        },
        children: [
          {
            path: 'db',
            lazy: async () => ({ Component: (await import('./admin/routerLazy')).DbPage }),
            handle: {
              crumb: () => <Breadcrumb.Item key="requests">DB</Breadcrumb.Item>,
            },
          },
          {
            path: 'requests',
            lazy: async () => ({ Component: (await import('./admin/routerLazy')).RequestsLogPage }),
            handle: {
              crumb: () => <Breadcrumb.Item key="requests">Requests Log</Breadcrumb.Item>,
            },
          },
          {
            path: 'organizations',
            lazy: async () => ({ Component: (await import('./admin/routerLazy')).OrganizationsPage }),
            handle: {
              crumb: () => <Breadcrumb.Item key="organizations">Organizations</Breadcrumb.Item>,
            },
          },
          {
            path: 'webhooks',
            lazy: async () => ({ Component: (await import('./admin/routerLazy')).WebhooksPage }),
            handle: {
              crumb: () => <Breadcrumb.Item key="webhooks">Webhooks</Breadcrumb.Item>,
            },
          },
          {
            path: 'baseUrls',
            lazy: async () => ({ Component: (await import('./admin/routerLazy')).BaseUrlsPage }),
            handle: {
              crumb: () => <Breadcrumb.Item key="baseUrls">Base URLs</Breadcrumb.Item>,
            },
          },
          {
            path: 'seeds',
            lazy: async () => ({ Component: (await import('./admin/routerLazy')).SeedsPage }),
            handle: {
              crumb: () => <Breadcrumb.Item key="seeds">Seeds</Breadcrumb.Item>,
            },
          },
        ],
      },
      {
        path: 'login/token',
        element: <LoginTokenPage />,
      },
      {
        path: 'login',
        element: <LoginStartPage />,
      },
      {
        path: 'event/:eventKey',
        element: <EventPage />,
      },
      {
        index: true,
        element: <EventCalendarPage />,
      },
      {
        path: ':year/:month',
        element: <EventCalendarPage />,
      }
    ],
  },
]);
const App: React.FunctionComponent = () => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#FF750A',
          borderRadius: 20,
        },
      }}
    >
      <MobileConfigProvider locale={enUS}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </MobileConfigProvider>
    </ConfigProvider>
  );
};

export default App;
