import { Alert, message } from 'antd';
import React, { useEffect } from 'react';

import { useStartAuth } from '../../auth';

export const LoginStartPage: React.FC = () => {
    const { error } = useStartAuth();

    useEffect(() => {
        if (error) {
            console.error(error);
            message.error('Error attempting to log you in');
        }
    }, [error]);

    return error
        ? <Alert message="Login error" description="Error attempting to log you in" type="error" showIcon />
        : <div>Logging you in...</div>;
};
export default LoginStartPage;