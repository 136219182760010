import dayjs from 'dayjs';

import type { IEvent } from '@events.pig.af/models';

export function hydrateDate(obj: undefined): undefined;
export function hydrateDate(obj: IEvent['startDate']): dayjs.Dayjs;
export function hydrateDate(obj: undefined | IEvent['startDate']): undefined | dayjs.Dayjs;
export function hydrateDate(obj: IEvent['startDate'] | undefined) {
    if (!obj) return;
    const { year, month, day, hour, minute } = obj;
    if (!hour) return dayjs(`${year}-${month}-${day}`);
    return dayjs(`${year}-${month}-${day}T${hour}:${minute ?? 0}`);
};
export default hydrateDate;