import { Alert, Skeleton, Space, Typography } from "antd";
import { Dayjs } from "dayjs";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getDomain } from "tldjs";

import AddToCalendar from "./AddToCalendar";
import { ApiState, useEvent, useIsAdmin } from "../../api";
import { Markdown } from "../Markdown";
import hydrateDate from "../../hydrateDate";
import type EventAdminMenu from "../../admin/EventAdminMenu";
import type { EventAdminMenuProps } from "../../admin/EventAdminMenu";

const { Paragraph, Title } = Typography;

type IEventApi = Awaited<ReturnType<typeof useEvent>>;

const Dash: React.FC = () => (
  <span style={{ padding: "0px 20px" }}>&minus;</span>
);

interface EventDateProps {
  startDate: Dayjs;
  endDate?: Dayjs | null;
}
const EventDate: React.FC<EventDateProps> = (props) => {
  const { startDate, endDate } = props;
  if (endDate) {
    if (startDate.isSame(endDate, "day")) {
      return (
        <span>
          {startDate.format("llll")}
          <Dash />
          {endDate.format("LT")}
        </span>
      );
    }
    return (
      <span>
        {startDate.format("llll")}
        <Dash />
        {endDate.format("llll")}
      </span>
    );
  }
  return <span>{startDate.format("llll")}</span>;
};

const displayLink = (url: string) => getDomain(new URL(url).hostname);

const EventWrapper = styled.div`
  padding: 24px;
`;

const EventHeaderWrapper = styled.div<{
  bgUrl: string | null;
}>`
  padding: 24px;
  background-image: url(${(props) => props.bgUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  color: black;
  position: relative;
`;

const EventDetailsWrapper = styled.div`
  padding-top: 24px;
  display: grid;
  grid-template-columns: 200px auto;
`;
const EventDetailsTitleComp = styled.div`
  padding: 10px 0px;
  & > span {
    font-weight: bold;
    color: white;
    background-color: black;
    padding: 10px;
    line-height: 2em;
  }
`;
const EventDetailsTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <EventDetailsTitleComp>
    <span>{children}</span>
  </EventDetailsTitleComp>
);
const EventDetailsValueComp = styled.div`
  padding: 10px 0px;
  & > span {
    color: white;
    background-color: black;
    padding: 10px;
    line-height: 2em;
  }
`;
const EventDetailsValue: React.FC<PropsWithChildren> = ({ children }) => (
  <EventDetailsValueComp>
    <span>{children}</span>
  </EventDetailsValueComp>
);

const LazyEventAdminMenu: React.FC<EventAdminMenuProps> = (props) => {
  const [AdminMenuComp, setAdminMenuComp] = useState<
    null | typeof EventAdminMenu
  >(null);
  useEffect(() => {
    import("../../admin/EventAdminMenu").then((m) =>
      setAdminMenuComp(() => m.default),
    );
  }, [setAdminMenuComp]);

  if (AdminMenuComp) {
    return <AdminMenuComp {...props} />;
  } else {
    return null;
  }
};

const EventActionsWrapper = styled(Space)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const EventHeader: React.FC<EventProps> = ({ event, eventKey }) => {
  const isAdmin = useIsAdmin();
  const loading = event === ApiState.loading;

  if (event === ApiState.failed) {
    return null;
  }

  const startDate = loading ? null : hydrateDate(event.startDate);
  const endDate = loading ? null : hydrateDate(event.endDate);
  const timeLabel = endDate ? "Time" : "Start time";

  const image = loading ? null : event.image;

  return (
    // <EventHeaderWrapper bgUrl={hash ? `/static/header-images/${hash}.png` : null}>
    <EventHeaderWrapper bgUrl={image}>
      <Title
        level={2}
        style={{ backgroundColor: "black", display: "inline", padding: "10px" }}
      >
        {loading ? "Loading..." : event.name}
      </Title>
      <br />
      {loading ? (
        <Skeleton active paragraph={false} />
      ) : (
        <Title
          level={3}
          style={{
            backgroundColor: "black",
            display: "inline",
            padding: "10px",
            paddingTop: "0px",
          }}
        >
          By {event.organization.name}
        </Title>
      )}
      <div style={{ paddingTop: "24px" }} />
      {loading ? (
        <Skeleton active paragraph={false} />
      ) : (
        <EventDetailsWrapper>
          <EventDetailsTitle>More info</EventDetailsTitle>
          <EventDetailsValue>
            <a href={event.url} target="_blank" rel="noreferrer">
              {displayLink(event.url)}
            </a>
          </EventDetailsValue>
          <EventDetailsTitle>{timeLabel}</EventDetailsTitle>
          <EventDetailsValue>
            <EventDate startDate={startDate!} endDate={endDate} />
          </EventDetailsValue>
        </EventDetailsWrapper>
      )}
      <EventActionsWrapper>
        {isAdmin.isAdmin && <LazyEventAdminMenu eventKey={eventKey} />}
        {!loading && <AddToCalendar event={event} />}
      </EventActionsWrapper>
    </EventHeaderWrapper>
  );
};

interface EventProps {
  event: IEventApi;
  eventKey: string;
}
const Event: React.FC<EventProps> = ({ event, eventKey }) => {
  const loading = event === ApiState.loading;
  if (event === ApiState.failed) {
    return <Alert type="error" message="Failed to load event." showIcon />;
  }

  return (
    <>
      <EventHeader event={event} eventKey={eventKey} />
      <EventWrapper>
        {loading ? (
          <Skeleton active />
        ) : (
          <Paragraph style={{ paddingTop: "20px" }}>
            <Markdown source={event.description} />
          </Paragraph>
        )}
      </EventWrapper>
    </>
  );
};

interface EventPageCompProps {
  eventKey: string;
}
const EventPageComp: React.FC<EventPageCompProps> = ({ eventKey }) => {
  const event = useEvent(eventKey);
  return <Event event={event} eventKey={eventKey} />;
};

export const EventPage: React.FC = () => {
  const params = useParams<{ eventKey: string }>();
  const nav = useNavigate();
  if (!params.eventKey) {
    nav("/");
    return null;
  }
  return <EventPageComp eventKey={params.eventKey} />;
};
export default EventPage;
