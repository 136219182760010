import React, { useEffect } from 'react';
import * as prod from 'react/jsx-runtime'
import rehypeRaw from 'rehype-raw';
import rehypeReact from 'rehype-react';
import rehypeSanitize from 'rehype-sanitize';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

const production = {Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs}

export const getMarkdownParser = (parser=null) =>
  (parser ? parser : unified())
  .use(remarkParse)

export const getMarkdownToReact = (parser=null) =>
  (parser ? parser : getMarkdownParser())
  .use(remarkRehype, {
    allowDangerousHtml: true,
  })
  .use(rehypeRaw)
  .use(rehypeSanitize)
  .use(rehypeReact, production as any);

export const Markdown: React.FC<{ source: string }> = props => {
  const { source } = props;
  const [children, setChildren] = React.useState<React.ReactElement | null>(null);

  useEffect(() => {
    const parser = getMarkdownToReact();
    parser.process(source)
      .then(file => {
        setChildren(file.result);
      })
      .catch(err => {
        console.error(err);
        setChildren(<>{ source }</>);
      });
  }, [source]);

  return children;
}
