import { Alert, Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive'

import { ApiState, useEvents } from '../../api';

type DisplayComponentModules = typeof import('./EventList') | typeof import('./EventCalendar');

export const EventCalendarPage: React.FC = () => {
    const [displayDate, setDisplayDate] = useState(dayjs());

    const fromDate = useMemo(() => displayDate.startOf('month').subtract(7, 'days'), [displayDate]);
    const toDate = useMemo(() => fromDate.add(50, 'days'), [fromDate]);

    const events = useEvents({ fromDate, toDate });

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const currentIsTabletOrMobile = useRef(isTabletOrMobile);
    currentIsTabletOrMobile.current = isTabletOrMobile;

    const [DisplayComponentModule, setDisplayComponentModule] = useState<DisplayComponentModules | null>(null);
    useEffect(() => {
        (async () => {
            setDisplayComponentModule(null);
            const initial = isTabletOrMobile;
            const module = await (isTabletOrMobile
                ? import('./EventList')
                : import('./EventCalendar'));
            if (currentIsTabletOrMobile.current !== initial) return;
            setDisplayComponentModule(module);
        })();
    }, [isTabletOrMobile, currentIsTabletOrMobile]);

    if (events === ApiState.failed)
        return <Alert type="error" message="Failed to load events." showIcon />;
    const calendarProps = events === ApiState.loading
        ? { loading: true, events: [] }
        : { loading: false, events: events.items };

    const DisplayComponent = DisplayComponentModule?.default;
    return (
        DisplayComponent
            ? <DisplayComponent {...calendarProps} onChange={setDisplayDate} value={displayDate} />
            : <Spin />
    );
};
export default EventCalendarPage;