import { useEffect, useState } from 'react';

export enum PromiseState {
    notStarted,
    pending,
    resolved,
    rejected,
};

// export function usePromise(input: null): [PromiseState.notStarted, null];
// export function usePromise<T>(input: Promise<T>): [PromiseState.notStarted, null];
// export function usePromise<T>(input: Promise<T>): [PromiseState.pending, null];
// export function usePromise<T>(input: Promise<T>): [PromiseState.resolved, T];
// export function usePromise<T>(input: Promise<T>): [PromiseState.rejected, unknown];

export function usePromise<T>(input: Promise<T> | (() => Promise<T>) | null) {
    const [v, setV] = useState<
        [PromiseState.notStarted, null] |
        [PromiseState.pending, null] |
        [PromiseState.resolved, T] |
        [PromiseState.rejected, unknown]
    >([PromiseState.notStarted, null]);

    useEffect(() => {
        if (!input) {
            setV([PromiseState.notStarted, null]);
        } else {
            setV([PromiseState.pending, null]);

            const promise = typeof input === 'function' ? input() : input;
            promise
                .then(res => { setV([PromiseState.resolved, res]); return res })
                .catch(err => { setV([PromiseState.rejected, err]); return err });
        }
    }, [input]);

    return v;
};
export default usePromise;