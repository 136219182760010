import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { Dayjs } from 'dayjs';
import jsonp from 'jsonp';

import { IGetEvent, IListEvents, IListHttpRequests, IListOrganizations, IListWebhooks, IPostWebhookRegen, IPatchWebhook, IPatchWebhookInput, IPostWebhooks, IPostWebhooksInput, IListBaseUrls, IPatchBaseUrlsInput, IListSeeds, IPostSeedRun, IPostSeedRunInput } from '@events.pig.af/models';
import { useEffect, useState } from 'react';

export enum ApiState {
    loading,
    failed,
};

const isLocalhost = () => document.location.hostname === 'localhost' || document.location.hostname.match('^127\.[0-9]+\.[0-9]+\.[0-9]+$');

const BASE_HOST = isLocalhost()
    ? 'http://events.127.0.0.1.sslip.io'
    : `${document.location.protocol}//${document.location.host}`;
const BASE_URL = `${BASE_HOST}/api/`;
const OAUTH_URL = `${BASE_HOST}/oauth2/`;
export const useAxios = makeUseAxios({
    cache: false,
    axios: axios.create({
        baseURL: BASE_URL,
        withCredentials: true,
    }),
});
export const useOauthAxios = makeUseAxios({
    axios: axios.create({
        baseURL: OAUTH_URL,
        withCredentials: true,
    }),
});

type UseSimpleApiReturn<T> = T | ApiState;
function useSimpleApi<T>(path: string, useFn = useAxios<T>): UseSimpleApiReturn<T> {
    const [{data, loading, error}] = useFn(path);
    if (loading) return ApiState.loading;
    if (error) return ApiState.failed;
    return data!;
};

export const useRequests = () => useSimpleApi<IListHttpRequests>('requests')
export const useResponseDataUrl = (responseId: string) => `${BASE_URL}responses/${responseId}/data`;
export const useOrganizations = () => useSimpleApi<IListOrganizations>('organizations');

export interface UseEventsProps {
    fromDate?: Dayjs;
    toDate?: Dayjs;
};
export const useEvents = (props: UseEventsProps) => {
    const { fromDate , toDate } = props;
    const isLocalhostNow = isLocalhost();
    const [externalIp, setExternalIp] = useState<string | ApiState>(ApiState.loading);
    useEffect(() => {
        if (!isLocalhostNow) return;
        jsonp('https://api.ipify.org?format=jsonp', (err, data) => {
            if (err) {
                setExternalIp(ApiState.failed);
                console.error(err);
            } else {
                setExternalIp(data.ip);
            }
        });
    }, [isLocalhostNow, setExternalIp]);
    const [{data, loading, error}, refetch] = useAxios<IListEvents>({
        url: 'events',
        params: {
            overrideIp: isLocalhostNow && externalIp !== ApiState.failed ? externalIp : undefined,
            fromDate: fromDate?.toISOString(),
            toDate: toDate?.toISOString(),
        },
    }, { manual: true })
    useEffect(() => {
        if (!isLocalhostNow) {
            refetch();
        } else if (externalIp !== ApiState.loading) {
            refetch();
        }
    }, [externalIp, isLocalhostNow, refetch, fromDate, toDate]);

    if ((isLocalhostNow && externalIp === ApiState.loading) || loading) return ApiState.loading;
    if (error) return ApiState.failed;
    if (!data) return ApiState.loading;
    return data;
};
export const useEvent = (key: string) => useSimpleApi<IGetEvent>(`events/${key}`);
export const useDeleteEvent = (key: string) => useAxios<{ ok: boolean }>({
    method: 'delete',
    url: `events/${key}`,
}, { manual: true });

export const useStats = (svc: string) => useSimpleApi<any>(`${svc}-svc/stats`);
export const useClearGptCache = () => useAxios<{ ok: boolean }>({
    method: 'post',
    url: 'gpt-svc/clear-cache',
}, { manual: true });

export const useWebhooks = () => useAxios<IListWebhooks>('webhooks/');
export const useNewWebhook = () => useAxios<IPostWebhooks, IPostWebhooksInput>({
    method: 'post',
    url: `webhooks/`,
}, { manual: true });
export const useEditWebhook = (key: string) => useAxios<IPatchWebhook, IPatchWebhookInput>({
    method: 'patch',
    url: `webhooks/${key}`,
}, { manual: true });
export const useDeleteWebhook = (key: string) => useAxios<{ ok: boolean }>({
    method: 'delete',
    url: `webhooks/${key}`,
}, { manual: true });
export const useRegenWebhookSecret = (key: string) => useAxios<IPostWebhookRegen>({
    method: 'post',
    url: `webhooks/${key}/regenerate-secret`,
}, { manual: true });

export const useBaseUrls = () => useAxios<IListBaseUrls>('scheduler/base-urls');
export const usePatchBaseUrl = (key: string) => useAxios<{ okay: boolean }, IPatchBaseUrlsInput>({
    method: 'patch',
    url: `scheduler/base-urls/${key}`,
}, { manual: true });

export const useSeeds = () => useAxios<IListSeeds>('seeder/seeds');
export const useRunSeed = () => useAxios<IPostSeedRun, IPostSeedRunInput>({
    method: 'post',
    url: `seeder/seeds/run`,
}, { manual: true });

export interface IUserInfo {
    user: string;
    email: string;
    groups: string[];
    preferredUsername: string;
};
export const useUserInfo = () => useOauthAxios<IUserInfo>({
    url: 'userinfo',
    method: 'get',
});

export const useIsAdmin = () => {
    const [i] = useUserInfo();

    if (i.loading) return { loading: true, isAdmin: false };
    if (i.data) return { loading: false, isAdmin: true };
    return { loading: false, isAdmin: false };
};