import useAxios from 'axios-hooks';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHref } from 'react-router-dom';

export interface OpenIdConfigurationResponse {
    authorization_endpoint: string;
    token_endpoint: string;
};
export interface TokenResponse {
    access_token: string;
    refresh_token: string;
};

export const useOpenIdBaseUrl = () => 'https://auth.pig.af/realms/master';
export const useOpenIdClientId = () => 'fucking-events-dev';
export const useOpenIdConfiguration = () => {
    const baseUrl = useOpenIdBaseUrl();
    return useAxios<OpenIdConfigurationResponse>(
        `${baseUrl}/.well-known/openid-configuration`,
    );
};

export const useStartAuth = () => {
    const clientId = useOpenIdClientId();
    const [{data, error}] = useOpenIdConfiguration();
    const redirectUri = useHref('/login/token');
    const authEndpoint = data?.authorization_endpoint;

    const url = useMemo<URL | null>(() => {
        if (!authEndpoint) return null;
        const u = new URL(authEndpoint);
        u.searchParams.set('response_type', 'code');
        u.searchParams.set('client_id', clientId);
        u.searchParams.set('scope', 'profile');
        u.searchParams.set('redirect_url', redirectUri);
        return u;
    }, [authEndpoint, clientId, redirectUri]);

    if (url) document.location = url.toString();

    return {error, loading: !error};
};

export interface UseTokenProps {
    code?: string | null;
    redirectUri: string;
};
export const useToken = ({code, redirectUri}: UseTokenProps) => {
    const clientId = useOpenIdClientId();
    const [{data: confData, error: confError, loading: confLoading}] = useOpenIdConfiguration();

    const form = useMemo<URLSearchParams | null>(() => {
        if (!code) return null;
        const f = new URLSearchParams();
        f.set('grant_type', 'authorization_code');
        f.set('code', code);
        f.set('redirect_uri', redirectUri);
        return f;
    }, [code, redirectUri]);

    const [{ data: tokenData, error: tokenError, loading: tokenLoading }, refetch] = (
        useAxios<TokenResponse>({
            method: 'POST',
            url: confData?.token_endpoint,
            data: form,
            auth: { username: clientId, password: '' },
        }, { manual: true })
    );

    useEffect(() => {
        if (confData && form) {
            refetch();
        }
    }, [confData, form, refetch]);

    return {
        data: tokenData,
        error: confError || tokenError,
        loading: confLoading || tokenLoading,
    };
};

export interface IAuthCtx {
    token: TokenResponse | null;
    setToken: (token: IAuthCtx['token']) => void;
};
const AuthCtx = createContext<IAuthCtx>({
    token: null,
    setToken: () => { throw new Error('AuthProvider used before initialization') },
});
export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [token, setTokenRaw] = useState<IAuthCtx['token']>(
        localStorage.getItem('events.pig.af.token')
            ? JSON.parse(localStorage.getItem('events.pig.af.token')!)
            : null
    );
    const setToken = useCallback((token: Parameters<typeof setTokenRaw>[0]) => {
        localStorage.setItem('events.pig.af.token', JSON.stringify(token));
        setTokenRaw(token);
    }, [setTokenRaw]);
    return <AuthCtx.Provider value={{ token, setToken }}>{children}</AuthCtx.Provider>;
};

export const useCurrentToken = () => useContext(AuthCtx).token;
export const useTokenSetter = () => useContext(AuthCtx).setToken;
export const useSetToken = (token: IAuthCtx['token']) => useTokenSetter()(token);